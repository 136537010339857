<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create Device Dialog"
    color="info"
  >
    <template #header>
      <h6 class="modal-title text-center">{{title}} 기준 설정</h6>
    </template>

    <div class='modal-body'>
      <form name='thresholdForm'>
        <div class='form-group'>
          <div class='row d-sm-down-none'>
            <div class='col-sm-3 col-lg-3'>
              <label>상태</label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최소값(도) <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>최대값(도) <strong class='text-danger'>*</strong></label>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <label>푸쉬알림</label>
            </div>
          </div>
        </div>

        <div class='form-group'>
          <div class="row" v-for="(threshold, index) in thresholds">
            <div class='col-sm-3 col-lg-3'>
              <input type='text' class='form-control text-center' :style="{'background-color': threshold.color, 'font-weight': 'bold'}" v-model='threshold.name' readonly>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 max=360 v-model='threshold._min' @change='changeRange(index, threshold, true)' :step=0.1>
            </div>

            <div class='col-sm-3 col-lg-3'>
              <input type='number' style="font-size:12px;" class='form-control' min=0 max=360 v-model='threshold._max' @change='changeRange(index, threshold, false)' :step=0.1>
            </div>
            <div class='col-sm-3 col-lg-3'>
              <CSwitch class="mr-1" color="info" :checked.sync="threshold.is_push" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>

    <Notify ref="notifyDialog"/>

  </KModal>
</template>

<script>
import loopback from '@/services/loopback';
import Threshold from '@/views/device/Threshold'

export default {
  name: 'SRDSThreshold',
  extends: Threshold
}
</script>
