<template>
  <div>
    <CCard>
      <CCardHeader>
        <h4 style="color:#00A8FF">{{ device.name }}</h4>
      </CCardHeader>
  
      <CCardBody>
          <CCol col="12" xl="12">
            <ul class="nav nav-tabs nav-pills">
              <li class="nav-item">
                <a tab class="nav-link" @click.prevent="setActive('tab3')" :class="{ active: isActive('tab3') }" href='#tab3'>이벤트 사진</a>
              </li>        
              <li class="nav-item">
                <a tab class="nav-link" @click.prevent="setActive('tab4')" :class="{ active: isActive('tab4') }" href='#tab4'>센서값 보기</a>
              </li>
              <li class="nav-item ">
                <a tab class="nav-link" @click.prevent="setActive('tab5')" :class="{ active: isActive('tab5') }" href='#tab5'>그래프 보기</a>
              </li>
              <li class="nav-item">
                <a tab class="nav-link" @click.prevent="setActive('tab6')" :class="{ active: isActive('tab6') }" href='#tab6'>작업 일지</a>
              </li>        
              <li class="nav-item">
                <a tab class="nav-link" @click.prevent="setActive('tab7')" :class="{ active: isActive('tab7') }" href='#tab7'>설정 변경</a>
              </li>                
            </ul>
          </CCol>   
       <div class="tab-content">
          <div class="tab-pane" id='tab3' :class="{ 'active show': isActive('tab3') }">
              <Camera
                  ref="cameraView"
                  :device_guid.sync="device_guid"
                  :device.sync="device"
              />
              <CRow>
                <CCol col="12" xl="12">
                  <CCard>
                    <CCardBody col="12" xl="12">
                      <CRow>
                        <div class="col-sm-12 col-lg-6" >
                            <HeatMapChart 
                              ref="heatmapchartright"
                              :content="sensor.data"
                              :device_guid.sync="device_guid"
                              :style="image_style"
                            />
                        </div>
                        <div class="col-sm-12 col-lg-6">
                            <HeatMapChart 
                              ref="heatmapchartleft"
                              :content="sensor.data"
                              :device_guid.sync="device_guid"
                              :style="image_style"
                            />
                        </div>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
          </div>
          <div class="tab-pane" id='tab4' :class="{ 'active show': isActive('tab4') }">
              <CCardBody>
                  <ProgressBar ref="progressbar" :content="sensor.data" />
              </CCardBody>
          </div>
          <div class="tab-pane" id='tab5' :class="{ 'active show': isActive('tab5') }">
            <HistoryMeasure
                ref="historyMeasure"
                :device_guid.sync="device_guid"
                :device.sync="device"
            />
          </div>
          <div class="tab-pane" id='tab6' :class="{ 'active show': isActive('tab6') }">
            <HistoryDevice
                ref=historyDevice
                :device_guid.sync="device_guid"
                :device.sync="device"
            />
          </div>
          <div class="tab-pane" id='tab7' :class="{ 'active show': isActive('tab7') }">
            <!--<Productinfo
                ref=productinfo
                :device_guid.sync="device_guid"
                :device.sync="device"
            />-->
            <CCard>
              <CCardBody>

              </CCardBody>
              <CCardFooter col="12" xl="12">
                <CButton color="warning" class="float-right mr-1" @click="openDialog('sensor')">센서 정보 관리</CButton>
              </CCardFooter>
            </CCard>
          </div>
        </div>      
      </CCardBody>
    </CCard>
  
    <DeviceInfo
        ref="deviceInfo"
        :isAdmin="is_admin"
        :isMobile="is_mobile"
        :isPeriodic="false"
        :device.sync="device"
        @hide="hideModal"
        @update="onUpdated"
    />
  </div>
</template>
  
<script>
  
import Device from '@/views/btfs/Device'
import DeviceInfo from '@/views/device/DeviceInfo'

import Event from '@/views/device/Event'
import Camera from '@/views/swfm/device/Camera';
import Search from '@/views/device/Search';
import Productinfo from '@/views/watek/Productinfo';

import HistoryMeasure from '@/views/device/HistoryMeasure';
import HistoryDevice from '@/views/device/HistoryDevice';

import HeatMapChart from '@/views/swfm/device/HeatMapChart'

// graph
import ProgressBar from './graph/ProgressBar.vue'


export default {
  name: 'SWFMDevice',
  extends: Device,
  components: {
    DeviceInfo,
    Camera,
    Event,
    Search,
    Productinfo,
    HistoryMeasure,
    HistoryDevice,
    HeatMapChart,
    ProgressBar
  },
  data () {
    return {
      activeItem: 'tab3',
    }
  },
  methods: {
    isActive (menuItem) {
      return this.activeItem === menuItem
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    getDates() {
      if (this.activeItem === 'tab5')
        return this.$refs.historyMeasure.getDates();
      else
        return this.$refs.historyDevice.getDates();
    },    
    getThmaldata() {
      var data = this.sensor.data;
      if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
          if(data[i].type == 35){
            if(data[i].id == 1)
              this.$refs.heatmapchartleft.generate(data[i].value)
            else if(data[i].id == 2)
              this.$refs.heatmapchartright.generate(data[i].value)                
          }
        }
      }

      var rows_length = 10;
      if (rows_length > 10) {
        rows_length = 10;
        this.options.height = 760;
      }
      //this.data = rows;
      this.setupImageStyle(rows_length);
      return;
    },
    getSensors() {
        var filter = {
          guid:this.device.site_guid
        }
        this.site = _.find(this.sites, filter) || this.site;
        this.sensor = this.device.sensor;
        this.getThmaldata();
    },     
    setupImageStyle(count) {
      var row_height = 50;
      var image_height = row_height * count;
      if (image_height > 760)
        image_height = 760
      this.image_style = {
        'width': '100%',
        'min-height': image_height + 'px',
        'max-height': image_height + 'px'
      }
    },
  }
}
</script>
