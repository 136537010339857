<template>
  <v-chart 
      :options="heatmap" 
      autoresize 
    />
</template>

<script>
import VueECharts from "vue-echarts";
import "echarts/lib/chart/heatmap";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/axis";
import "echarts/lib/component/grid";
import "echarts/lib/component/visualMap";



export default {
  name: 'HeatMapChart',
  components: {
    "v-chart": VueECharts,
  },
  props:{
    content: {
      type: Array
    },
    device: {
      type: Object
    }
  },
  mounted: function(){
    // console.log("heatmap mounted ")
    // console.log(this.content)
    //this.generate(this.content);
  },
  data() {
    return {
      heatmap: {
        tooltip: {
          position: "center",
        },
        animation: false,
        grid: {
          top: "0px",
          left: '0px',
          right: '0px',
        },
        xAxis: { //x축
          type: "category",
          show: false,
        },
        yAxis: { //y축
          type: "category",
          show: false,
        },
        visualMap: {//범주
          min: 20,
          max: 60,//최소 최대값으로 범위 지정
          calculable: true,
          orient: "horizontal",
          left: "center",
          top: "bottom",
          inRange: {
              color: [
                '#313695',
                '#4575b4',
                '#74add1',
                '#abd9e9',
                '#e0f3f8',
                '#ffffbf',
                '#fee090',
                '#fdae61',
                '#f46d43',
                '#d73027',
                '#a50026'
              ]
            }          
        },
        series: [//실 데이터

        ],
      },
    };
  },
  methods:{
    generate(contents){
      //console.log(contents);

      var data =[];
      var mlx_data = [];
      
      mlx_data = contents;
      var temp = [];
      for(var h=0;h<24; h++)
        for(var w=0;w<32;w++){
          temp.push([w, h, mlx_data[h * 32 + w] || "-"]);
        }
      data=temp;
      
      var series_datas = []
      series_datas.push({
            type: "heatmap",
            data: data,
            label: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              progressive: 1000,
              animation: false
            },
      })

      this.heatmap.series = series_datas;
      this.heatmap.visualMap.min = -20//Math.min(...mlx_data)
      this.heatmap.visualMap.max = 60//Math.max(...mlx_data)+20
    }
  }
};
</script>

<style scoped>
.echarts {
  width: 100% !important;
  height: 100% !important;
}
</style>