<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Create User Dialog"
    color="info"
    size="lg"
  >

    <template #header>
      <h6 class='modal-title text-center'>{{ title }}</h6>
    </template>

    <div class='modal-body'>
      <form name='deviceForm'>
        <div class="row">
          <div class="col-sm-6 col-lg-6">
            <label>시리얼번호 <strong class='text-danger'>*</strong></label>
            <input type='text' class='form-control' placeholder='Enter the id of device' v-model='serialNo' :required='true' :disabled='true'>
          </div>
          <div class="col-sm-6 col-lg-6">
            <label>명칭 </label>
            <input type='text' class='form-control' placeholder='Enter the name of device' v-model='device.name'>
          </div>
        </div>

        <div class="search-margin"></div>

        <div class="row">
          <div class="col-sm-8 col-lg-8">
            <label><strong class='text-danger'>데이터 주기보고 시간</strong> <small>[주기보고 시간 선택]</small></label>
              <select id = "upload_cron" @blur='cronValidate("upload_cron", "cronString", "full")' :placeholder='cronDescription' class='form-control' v-model='cronString'>
                <option v-for="cronString in cronStringTypes" :value="cronString.value" v-bind:key="cronString.id">{{cronString.name}} </option>
              </select>
          </div>
          <div class="col-sm-4 col-lg-4" v-if='!isMobile'>
            <label>장비사용여부 </label>
            <br>
            <CSwitch class="mr-1" color="info" v-bind="oLabelTxt" :checked.sync="device_active" size="sm" :disabled='isAdmin == false'/>
          </div>
        </div>

        <div class="search-margin"></div>

        <div class="row" v-show='isPeriodic == true && service_type == "sis" || service_type == "sis.valve"'>
          <div class="col-sm-8 col-lg-8">
            <label><strong class='text-danger'>물공급 시간</strong> <small>[공급시간 선택]</small></label>
              <select id = "powering_cron" @blur='cronValidate("powering_cron", "poweringCron", "sub")' :placeholder='cronDescription' class='form-control' v-model='poweringCron'>
                <option v-for="poweringCron in waterStringTypes" :value="poweringCron.value" v-bind:key="poweringCron.id">{{poweringCron.name}} </option>
              </select>            
            <!--<input type='text' id='powering_cron' @blur='cronValidate("powering_cron", "poweringCron", "sub")' :disabled="isMobile" class='form-control' placeholder='*/10 3 3' v-model='poweringCron'>-->
          </div>
          
          <div class="col-sm-4 col-lg-4" v-if='service_type == "sis"'>
            <label><strong class='text-danger'>저수조 수위</strong> <small>(리터)</small></label>
            <br>
            <div class="row">
              <div class="col-sm-5 col-lg-5">
                <input type='text' id='water_level' class='form-control' placeholder='0' v-model='device.water_level'>
              </div>
              <div class="col-sm-7 col-lg-7">
                <CSwitch class="mr-1" color="info" shape="pill" v-bind="pLabelTxt" :checked.sync="water_level_active" size="sm" :disabled='isAdmin == false'/>
              </div>
            </div>
          </div>
          <div class="col-sm-4 col-lg-4" v-if='service_type == "sis.valve"'>
            <label><strong class='text-danger'>자동관수</strong> <small>(월동시 OFF)</small></label>
            <br>
            <CSwitch class="mr-1" color="info" v-bind="watering_check" :checked.sync="watering_active" size="sm"/>
          </div>
        </div>

        <div class="search-margin"></div>

        <div class="row" v-show='isPeriodic == true && service_type == "protect"'>
          <div class="col-sm-8 col-lg-8">
            <label><strong class='text-danger'>양액공급 주기</strong> <small>[주기(분) 기간(분) 횟수]</small></label>
            <input type='text' id='nutrient_cron' @blur='cronValidate("nutrient_cron", "nutrientCron", "sub")' :disabled="isMobile" class='form-control' placeholder='*/10 3 3' v-model='nutrientCron'>
          </div>
          <div class="col-sm-4 col-lg-4" v-if='!isMobile'>
            <label><strong class='text-danger'>양액통 수위</strong> <small>(리터)</small></label>
            <br>
            <div class="row">
              <div class="col-sm-5 col-lg-5">
                <input type='text' id='nutrient_level' class='form-control' placeholder='0' v-model='device.nutrient_level'>
              </div>
              <div class="col-sm-7 col-lg-7">
                <CSwitch class="mr-1" color="info" shape="pill" v-bind="pLabelTxt" :checked.sync="nutrient_level_active" size="sm" :disabled='isAdmin == false'/>
              </div>
            </div>

          </div>
        </div>

        <div class="search-margin"></div>

        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item navbar-custom">
                <a tab class="nav-link ng-binding active" data-toggle="tab" id=' #tab0' href='#tab0' role="tab">위치 변경</a>
              </li>
              <li class="nav-item navbar-custom ">
                <a tab class="nav-link ng-binding" data-toggle="tab" id='#tab2' href='#tab1' role="tab">서비스 정보</a>
              </li>
            </ul>

            <div class="tab-content">
              <div class="tab-pane active" id='tab0' role="tabpanel">
                <div class="search-margin"></div>
                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                    <label>주소</label>
                    <input type='text' id='input_address' class='form-control' placeholder='Enter the address of device' v-model='device.address'>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <label>설치 일자</label>
                    <div class='input-group'>
                      <date-picker v-model="install_date" type="datetime" format="YYYY-MM-DD HH:mm:ss" style="width:100%;"></date-picker>
                    </div>
                  </div>

                </div>
                <div class="search-margin"></div>

                <div class="row">
                  <div class="col-sm-6 col-lg-6">
                    <label>위도</label>
                    <input type='text' id='input_latitude' class='form-control' placeholder='위도' v-model='device.latitude'>
                  </div>
                  <div class="col-sm-6 col-lg-6">
                    <label>경도</label>
                    <input type='text' id='input_longitude' class='form-control' placeholder='경도' v-model='device.longitude'>
                  </div>
                </div>
                <div class="search-margin"></div>
                <div class="row apps-container">
                  <div class="col-sm-12 col-lg-12">
                    <GoogleMap ref="googleMap" :device_guid="device.guid" @latLng="onGeoCode" style="min-height:100%;padding-top:0.1%;padding-bottom:0.1%;" />
                  </div>
                </div>

              </div>

              <div class="tab-pane" id='tab1' role="tabpanel">
                <div class="row">
                  <div class="col-sm-4 col-lg-4">
                    <label>네트웍 타입</label>
                    <input type='text' id='input_networktype' class='form-control' placeholder='네트웍 타입' v-model='device.network_type' :disabled='isAdmin == false'>
                  </div>
                  <div class="col-sm-4 col-lg-4">
                    <label>서비스 타입</label>
                    <select class='form-control' style="height:34px" v-model='device.service_type' :disabled='!isAdmin'>
                      <option v-for="service in service_type_info" :value="service.key" v-bind:key="service.key">{{service.value}} </option>
                    </select>
                    <!-- <input type='text' id='input_service_type' class='form-control' placeholder='서비스 타입' v-model='device.service_type' :disabled='isAdmin == false'> -->
                  </div>
                </div>
                <div class="search-margin"></div>
                <div class="row">
                  <div class="col-sm-12 col-lg-12">
                    <div class='form-group'>
                      <label>설명</label>
                      <textarea class='form-control' rows='2' placeholder='' v-model='device.description'></textarea>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>

      </form>
    </div>

    <template #footer>
      <CButton @click="register()" color="info">저장</CButton>
      <CButton @click="cancel()" color="light">취소</CButton>
    </template>
  
    <Confirm
        ref="confirmDialog"
        title="확인"
        which="update"
        @hide="hideModal"
    />

    <Notify ref="notifyDialog"/>

  </KModal>

</template>

<script>
import moment from 'moment';
import CronParser from 'cron-parser'
import loopback from '@/services/loopback';

import GoogleMap from '@/views/dashboard/GoogleMap'
import utils from '@/services/utils';

import { CRONSTRING_TYPES, WATERING_TYPES } from '@/constants/deviceSettings' 

const WATER_LEVEL_MAX = 100;
var SERVICE_TYPE = utils.getServiceTypes();

export default {
  name: 'DeviceInfo',
  components: {
    GoogleMap
  },
  props: {
    isAdmin: Boolean,
    isMobile: Boolean,
    isPeriodic: {
      type: Boolean,
      default: true
    },
    device: {
      type: Object
    }
  },
  data () {
    return {
      showModal: false,
      title: '디바이스 정보',
      which: 'create',
      isEditMode: false,
      service_type: '',
      serialNo: '',
      cronDescription: '0 0,8,16 * * * [분 시 일 * *]',
      cronString: '',
      poweringCron: '',
      nutrientCron: '',
      install_date: new Date('2017-06-21'),
      device_active: false,
      watering_active: false,
      water_level_active: false,
      nutrient_level_active: false,
      water_level_max: WATER_LEVEL_MAX,
      oLabelTxt: {
        labelOn: 'ON',
        labelOff: 'OFF'
      },
      watering_check: {
        labelOn: 'ON',
        labelOff: 'OFF'
      },
      pLabelTxt: {
        labelOn: 'P',
        labelOff: 'E'
      },
      service_type_info: _.map(SERVICE_TYPE.DEVICE, function(value, key) {
        return { key: key, value: value}}
      )
    }
  },
  created() {
    var thresholds = utils.getThresholds();
    var threshold = _.find(thresholds, {sensor_type: 19}) ||  { maximum : WATER_LEVEL_MAX }
    this.water_level_max = threshold.maximum || WATER_LEVEL_MAX;
  },
  computed: {
    fitered_site() {
      return _.isUndefined(this.site_list) ? [] : this.site_list.filter(item => item.checked);
    },
    cronStringTypes(){ return CRONSTRING_TYPES},
    waterStringTypes(){ return WATERING_TYPES}
    // default_site() {
    //   return _.isUndefined(this.user.default_site_guid) ? '' : this.user.default_site_guid
    // }
  },
  watch: {
    device: {
      deep: true,
      handler(val) {
        this.serialNo = 'SN-' + this.device.guid;
        this.install_date = new Date(this.device.install_date);
        this.device_active = this.device.status === 0;
        this.watering_active = this.poweringCron == '' ? false : true;
        this.water_level = this.device.water_level;
        this.nutrient_level = this.device.nutrient_level;
        this.water_level_active = this.water_level !== -1;
        this.service_type = this.device.service_type;
        this.cronString = this.device.upload_cron ? this.device.upload_cron.slice(2) : '';
        this.poweringCron = this.device.powering_cron ? this.device.powering_cron : '';
        this.nutrientCron = this.device.nutrient_cron ? this.device.nutrient_cron : '';
      }
    },
  },
  methods: {
    cronValidate(element, target, type='full') {
      var cronString = _.get(this.device, element);
      if (_.isUndefined(cronString)) this.device[element] = cronString = '';

      var value = document.getElementById(element).value;
      var cronArray = value.replace(/\s+/g, " ").split(" ");
      var length = type == 'full' ? 5 : 3

      if (cronArray.length !== length) {
        document.getElementById(element).value = cronString;
        return;
      }
      try {
        var full_string = type === 'full' ? value : '{0} * * * *'.format(cronArray[0])
        CronParser.parseExpression(full_string);
        this[target] = value;
      } catch (err) {
        console.log('[cron parser] error : ' + err.message);
        this.$refs.notifyDialog.show(err.toString())
        document.getElementById(element).value = cronString;
      }
    },
    onGeoCode(event) {
      console.log('DeviceInfo::onGeoCode event:', event)
      this.device.latitude = event.latitude;
      this.device.longitude = event.longitude;
      this.device.address = event.address;
    },
    hideModal(event) {
      if (event.data !== 'confirm' || event.which !== 'update') {
        return; // cancel
      }

      var where = {
        guid: this.device.guid
      }

      var data = {
        upload_cron: '',
        name: this.device.name,
        service_type: this.service_type,
        description: this.device.description,
        address: this.device.address,
        latitude: this.device.latitude,
        longitude: this.device.longitude,
        status: this.device_active ? 0 : 1
      }

      if (!_.isEmpty(this.cronString)) {
        data.upload_cron = '0 ' + this.cronString;
      }
      else{
        data.upload_cron = this.device.upload_cron;
      }
      data.install_date = moment(this.install_date).utc().format('YYYY-MM-DD HH:mm:ss')
      if (!_.isEmpty(this.poweringCron)) {
        if(this.device.service_type === 'sis.valve')
          data.powering_cron = this.watering_active ? this.poweringCron : '';
        else
          data.powering_cron = this.poweringCron;
        if (this.water_level >= 0 && this.water_level <= this.water_level_max)
          data.water_level = this.water_level;
      } else {
        if(this.device.service_type === 'sis'){
          data.water_level = -1;
          data.powering_cron = '';
        }
      }
      if (!_.isEmpty(this.nutrientCron)) {
        data.nutrient_cron = this.nutrientCron;
        if (this.nutrient_level >= 0 && this.nutrient_level <= this.water_level_max - 50)
          data.nutrient_level = this.nutrient_level;
      } else {
        data.nutrient_level = -1;
      }
      this.$store.dispatch('rest/upsertWithWhere', {model:'devices', where:where, data:data})
        .then(res => {
          // console.log('DeviceInfo upsert OK: ', res)
          let msg = {
            name: this.$options.name,
            which: this.which,
            data: res
          }
          this.$emit('update', msg)
          this.showModal = false
        })
        .catch(err => {
          console.log('DeviceInfo upsert Err: ', err.toString())          
          this.$refs.notifyDialog.show(err.toString())
          this.showModal = false
        })

    },
    register() {
      this.$refs.confirmDialog.show('수정하시겠습니까?', '상황판 저장', undefined, 'update');      
    },
    cancel() {
      this.showModal = false
    },
    show(sensor_data) {
      this.device.sensor = sensor_data
      this.$refs.googleMap.update({data: [this.device]})
      this.showModal = true
    }
  }

}
</script>

<style scoped>
.search-margin {
  margin: 10px 0 10px;
}
.apps-container {
  /* border: 2px dashed blue; */
  border: 1px solid rgb(152, 152, 160);
  margin: 10px 10px 0 0;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
</style>