<template>
    <div class="progress-container">
        <div class="progressbar" v-for="(item, index) in test" :key="item.index">
            <template v-if="item.type === 9">
                <vue-ellipse-progress :progress="detectedSmokeToToggle(item.value)" :size="180" thickness="5" dot="7" fontSize="1.5rem" class="progressbar">
                    <span slot="legend-value">{{ sensorType[index].value }}</span>
                    <p slot="legend-caption">{{ sensorType[index].unit }}</p>
                </vue-ellipse-progress>
            </template>
            <template v-else>
                <vue-ellipse-progress :progress="item.value" :size="180" thickness="5" dot="7" fontSize="1.5rem" class="progressbar">
                    <span slot="legend-value">{{ sensorType[index].value }}</span>
                    <p slot="legend-caption">{{ sensorType[index].unit }}</p>
                </vue-ellipse-progress>
            </template>
        </div>
        <BatteryBar ref="batterybar" :content="content" />
    </div>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress'
import BatteryBar from '@/views/swfm/device/graph/BatteryBar'
import utils from '@/services/utils'


export default {
    name: 'ProgressBar',
    components: {
        VueEllipseProgress,
        BatteryBar
    },
    props: {
        content: {
            type: Array
        },
    },

    data() {
        return {
            sensorType: []
        }
    },
    computed: {
        test() {
            return this.setData(this.content)
        }
    },
    methods: {
        setData(content) {

            if(content) {
                var data = content //object

                data = data.filter(item => item.type !== 1 && item.type !== 3 && item.type !== 35)

                data.map(item => {
                    item.value = Math.round(item.value * 100) / 100
                })
                
                var testArray = []

                for (var i = 0; i < data.length; i++) {
                    var name = utils.getSensorTypeName(data[i].type, data[i].id, false);
                    var arr = []
                    var obj = {}
                    arr = name.split(" ")
                    obj['unit'] = arr[0]
                    obj['value'] = arr[1]
                    testArray.push(obj)
                }

                this.sensorType = testArray

                return data
            }
        },

        detectedSmokeToToggle(item) { // 감지 or NO
            if(item.value > 0) { 
                return 100; 
            }
            return 0 
        },
    }
}
</script>

<style scoped>
.progress-container {
    display: flex;
    flex-wrap: wrap;
}

.progressbar {
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;

}
</style>