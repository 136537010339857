<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody col="12" xl="12">
          <CRow>
            <div class="col-sm-6 col-lg-6" style="padding:6px;" v-for="(view, i) in screens">
              <div style="text-align:center;color:white;background-color:#f08b28">
                <h3>{{view.title}}</h3>
              </div>

              <div v-if="!view.deviceImages.length">
                <div class="text-center border p-5">
                  <h4>측정된 이미지 파일 없음.<br/></h4>
                </div>
              </div>
              <div :id="'device-img' + view.direction" class="carousel slide border px-2" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#device-img" :data-slide-to="index" v-for="(img, index) in view.deviceImages" :class='{active:index==0}'></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item" v-for="(img, index) in view.deviceImages" :class='{active:index==0}'>
                    <img class="d-block w-100" :src="img.url" @click="openDialog(img)" alt="">
                    <div class="carousel-caption">
                      <p>{{img.label}}<br>{{img.created_at}}</p>
                    </div>
                  </div>
                </div>
                <a class="carousel-control-prev" :href="'#device-img' + view.direction" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" :href="'#device-img' + view.direction" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </CRow>
        </CCardBody>
      </CCard>

    </CCol>

    <ImageDialog ref="imageDialog"/>
    
  </CRow>
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';

import EventBus from '@/services/EventBus'
import ImageDialog from '@/views/btfs/ImageDialog'

export default {
  name: 'Camera',
  components: {
    ImageDialog
  },
  props: {
    device_guid : {
      type: String,
      default: undefined
    },
    device: {
      type: Object
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.getDeviceImages();
    EventBus.$on('reload', this.reloadListener);
  },
  mounted() {
  },
  beforeDestroy(){
    EventBus.$off('reload', this.reloadListener)
  },
  data () {
    return {
      isAdmin: this.$store.state.auth.is_admin,
      storage: "storages",
      screens: [
        {
          title: '1번 카메라',
          direction: 'left',
          deviceImages: [],
        },
        {
          title: '2번 카메라',
          direction: 'right',
          deviceImages: [],
        }
      ]
    }
  },
  methods: {
    getDeviceImages() {
      var self = this
      this.screens.forEach(view => {
        view.deviceImages = [];
      })
      /*****************************************************/
      //var dates = this.$parent.getDates();
      /*****************************************************/
      //var from = moment(dates[0]).format('YYYY-MM-DD HH:mm:ss');
      //var to = moment(dates[1]).format('YYYY-MM-DD HH:mm:ss');

      var filter = {
          where: {
            and: [{
                device_guid: this.device_guid
              },
            ]
          },
          order: 'created_at desc',
          limit: 20,
      }

      this.$store.dispatch('rest/find', {model:'photos',filter:filter})
        .then(function(response) {
          var pushImage = function(info) {
            var path = '{0}/{1}s/{2}/download/{3}'.format(loopback.defaults.baseURL, info.storage, self.device_guid, info.file_name)
            var index = info.flow_type === 'in' ? 0 : 1;
            self.screens[index].deviceImages.push({
              url: path,
              created_at: moment(info.created_at).format('YYYY년 MM월 DD일 HH시 mm분 ss초')
            })
          }
          for (var i = 0; i < response.length; i++) {
            var info = response[i]
            if (_.isUndefined(info.storage)) continue;
            // if (_.isUndefined(info.file.crop)) continue;
            pushImage(info);
          }
        })
    },
    openDialog(img) {
      this.$refs.imageDialog.show(img)
    },
    reloadListener() {
      var dates = this.$parent.getDates();
      console.log('Camera::reloadListener dates:', dates)
      this.getDeviceImages();
    },
  }
}
</script>

<style scoped>
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.50rem;
}
.row-margin {
  margin: 10px 0 10px;
}

</style>